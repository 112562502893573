$('.js-group-selector').click(function(){

    var shift_id = $(this).data('shift_id');
    var group_name = $(this).data('group_name');

    $('.js-group-selector').removeClass('active');
    $('input[name="group_id"]').val($(this).data('group_id'));
    $(this).addClass('active');

    $('.js-group_name').html("");
    $('[data-shift_id="'+shift_id+'"] .js-group_name').html(": "+group_name);

    $('.options-bx__tit').removeClass('active');
    $('[data-shift_id="'+shift_id+'"] .options-bx__tit').addClass('active');

    $('.js-toggle').removeClass('js-toggle');
});


