var toggleItem = $('.options-bx__it');
var toggleTitle = $('.options-bx__tit');
var toggleList = $('.options-bx__list');

$(toggleTitle).click(function(e){
  $(toggleTitle).removeClass('js-toggle');
  $(toggleList).removeClass('js-toggle');
  $(toggleItem).removeClass('js-toggle');
  $(this).toggleClass('js-toggle');
  var toggleIndex = $(this).data('option');
  $('.options-bx__list--'+toggleIndex+'').toggleClass('js-toggle');
});
