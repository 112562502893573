$(window).scroll(function() {
  var header = $('.header');
  if($(this).scrollTop()>0){
    $(header).addClass('js-sticky');
  } else {
    $(header).removeClass('js-sticky');
  }
});

var langCurrent = $('.lang-sel__current');
var langToggle = $('.lang-sel__toggle');

$(langCurrent).click(function(){
  $(langToggle).toggleClass('js-show');
  $(langCurrent).toggleClass('js-show');
});

var menuToggle = $('.m-toggle');
var menu = $('.menu');
var menuHamb = $('.m-hamb');

$(menuToggle).click(function(){
  $(this).toggleClass('js-open');
  $(menuHamb).toggleClass('js-open');
});